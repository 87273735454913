import Script from 'next/script'
import React, { useContext } from 'react'

import { ENV } from '@/config/environment'
import HNContext from '@/context/HNContext'

export default function HNPaddleAnalytics() {
  const { userProfile } = useContext(HNContext)

  if (!ENV.PADDLE_ANALYTICS_AUTHKEY) return null
  if (!userProfile?.is_admin_of_organization || !userProfile?.email) return null

  return (
    <>
      <Script
        src='https://cdn.paddle.com/paddle/v2/paddle.js'
        onLoad={() => {
          window.Paddle?.Setup({
            seller: 99999,
            pwAuth: ENV.PADDLE_ANALYTICS_AUTHKEY,
            pwCustomer: { email: userProfile.email },
          })
        }}
      />
    </>
  )
}
